export const environment = {
  production: true,
  makecodeSandboxUrl:
    "https://arcade.makecode.com/v1.12.32?controller=1&skillsmap=1",
  apiUrl: "https://app-curriculum-api-staging.azurewebsites.net/",
  imagesHostUrl: "https://app-curriculum-api-staging.azurewebsites.net/",
  filesHostUrl: "https://app-curriculum-api-staging.azurewebsites.net/files",
  assetsUrl: "https://assets.codeninjas.com",
  auth: {
    authority:
      "https://login.microsoftonline.com/5579b019-54e6-459c-9753-f2b37829ba5e",
    clientId: "752eab2e-2980-4734-9b94-1d3d43f5caf6",
    redirectUri: "https://curriculum.codeninjas.com",
  },
  publishAllowedMails: [
    "shubham.lad@softway.com",
    "johny@softway.com",
    "thuc@codeninjas.com",
    "ed.kim@codeninjas.com",
    "sara.smolevitz@codeninjas.com",
    "bill.marsland@codeninjas.com",
    "polly.smith@codeninjas.com",
    "chaitanya.ponnapati@codeninjas.com",
    "gautam.sharma@codeninjas.com",
  ],
  envName: "prod",
};
